import React, { useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  Dimensions,
  Image,
} from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useOrganization } from "context/OrganizationContext";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import * as FiIcons from "react-icons/fi";
import { useBusinessCampaigns } from "context/Business/BusinessCampaignsContext";
import moment from "moment";
import LinearProgressWithLabel from "components/Progress/LinearProgressWithLabel";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { BusinessTopDonorCard } from "components/Cards/Business/BusinessTopDonorCard";
import { BusinessTopNonprofitCard } from "components/Cards/Business/BusinessTopNonprofitCard";
import { BusinessTopCausesCard } from "components/Cards/Business/BusinessTopCausesCard";
import { causes } from "constants/Data";
import useMediaQuery, { breakPoints } from "hooks/useMediaQuery";
import { useWindowDimensions } from "react-native-web";

const { height } = Dimensions.get("window");


export function BusinessDashboard() {
  const { selectedOrg, userType } = useOrganization();
  const { getResponsiveValue } = useMediaQuery();
  const { activeCampaigns, activeCampaign } = useBusinessCampaigns();
  const {
    topDonors,
    topNonprofits,
    topCauses,
    totalDonationsYTD,
    totalMatchedDonationsYTD,
    totalHoursVolunteeredYTD,
    totalNonprofitsDonatedToYTD,
  } = useBusinessDonations();
  const { width } = useWindowDimensions();

  const [loading, setLoading] = useState(false);

  const styles = getStyles(getResponsiveValue);

  // console.log('getResponsiveValue', breakPoints, width);

  return (
    <>
      {!selectedOrg || loading ? (
        <View style={{ flexGrow: 1, height: height - 100 }}>
          <ActivityIndicator
            animating
            size={"large"}
            style={styles.activity}
            color={COLORS.primary.lightBlue}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <PrimaryText fontSize={24} fontWeight={800} style={styles.header}>
            {`Dashboard`}
          </PrimaryText>

          {/* {selectedOrg?.type === "business" && userType !== "Leader" ? null : ( */}
          <View style={styles.body}>
            {/** ROW 1 */}
            <View
              style={[
                styles.row,
                {
                  flexDirection: "row",
                  gap: 24,
                  paddingHorizontal: 24,
                  marginTop: 24,
                },
              ]}
            >
              <ShadowContainer
                style={[styles.card, { minWidth: 130, margin: 0 }]}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={styles.cardTitle}
                  >
                    Total Donations YTD
                  </PrimaryText>
                  <PrimaryText
                    fontSize={42}
                    fontWeight={800}
                    style={styles.statGreen}
                  >
                    {dollarFormatter.format(totalDonationsYTD)}
                  </PrimaryText>
                </View>
              </ShadowContainer>
              <ShadowContainer
                style={[styles.card, { minWidth: 130, margin: 0 }]}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={styles.cardTitle}
                  >
                    {`Total Matched\nDonations YTD`}
                  </PrimaryText>
                  <PrimaryText
                    fontSize={42}
                    fontWeight={800}
                    style={styles.statGreen}
                  >
                    {dollarFormatter.format(totalMatchedDonationsYTD)}
                  </PrimaryText>
                </View>
              </ShadowContainer>

              {/* <ShadowContainer
                  style={[styles.card, { minWidth: 130, margin: 0 }]}
                >
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <PrimaryText
                      fontSize={20}
                      fontWeight={400}
                      style={styles.cardTitle}
                    >
                      {`Total Hours\nVolunteered YTD`}
                    </PrimaryText>
                    <PrimaryText
                      fontSize={42}
                      fontWeight={800}
                      style={styles.statBlue}
                    >
                      {totalHoursVolunteeredYTD}
                    </PrimaryText>
                  </View>
                </ShadowContainer> */}

              <ShadowContainer
                style={[styles.card, { minWidth: 130, margin: 0 }]}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={styles.cardTitle}
                  >
                    {`Total Nonprofits\nSupported YTD`}
                  </PrimaryText>
                  <PrimaryText
                    fontSize={42}
                    fontWeight={800}
                    style={styles.statBlue}
                  >
                    {totalNonprofitsDonatedToYTD}
                  </PrimaryText>
                </View>
              </ShadowContainer>
            </View>

            {/** ROW 2 */}

            {/* <View style={styles.row}>
              <ShadowContainer style={styles.card}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={styles.cardTitle}
                  >
                    Giving Trends
                  </PrimaryText>

                  {true ? (
                    <View style={styles.center}>
                      <FiIcons.FiActivity
                        style={{
                          minHeight: 140,
                          maxHeight: 222,
                          minWidth: 140,
                          maxWidth: 222,
                        }}
                        color={COLORS.primary.neutral}
                      />
                      <PrimaryText
                        fontSize={24}
                        fontWeight={800}
                        style={styles.neutral}
                      >
                        Trends Will Here... One Day
                      </PrimaryText>
                    </View>
                  ) : null}
                </View>
              </ShadowContainer>

              <View style={{ flex: 1 }}>
                <ShadowContainer style={styles.card}>
                  <View>
                    <PrimaryText
                      fontSize={20}
                      fontWeight={400}
                      style={styles.cardTitle}
                    >
                      {`Donations`}
                    </PrimaryText>
                    {true ? (
                      <View style={styles.center}>
                        <FiIcons.FiBarChart2
                          color={COLORS.primary.neutral}
                          style={{
                            minHeight: 70,
                            maxHeight: 120,
                            minWidth: 70,
                            maxWidth: 120,
                          }}
                        />
                      </View>
                    ) : null}
                  </View>
                </ShadowContainer>

                <ShadowContainer style={styles.card}>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <PrimaryText
                      fontSize={20}
                      fontWeight={400}
                      style={styles.cardTitle}
                    >
                      {`Volunteering`}
                    </PrimaryText>
                    {true ? (
                      <View style={styles.center}>
                        <FiIcons.FiBarChart2
                          color={COLORS.primary.neutral}
                          style={{
                            minHeight: 70,
                            maxHeight: 120,
                            minWidth: 70,
                            maxWidth: 120,
                          }}
                        />
                      </View>
                    ) : null}
                  </View>
                </ShadowContainer>
              </View>
            </View> */}

            {/** ROW 3 */}
            <View style={[styles.row]}>
              {/* {width > 1515 && ( */}
              <ShadowContainer
                style={[styles.card, { flex: 1, minWidth: 200 }]}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={styles.cardTitle}
                  >
                    Top Employee
                    <PrimaryText
                      fontSize={20}
                      fontWeight={800}
                      style={styles.titleBlue}
                    >
                      {" "}
                      Donors
                    </PrimaryText>
                  </PrimaryText>
                  {/** GRAPH */}
                  {!topDonors?.length > 0 ? (
                    <View style={styles.center}>
                      <FiIcons.FiUser
                        color={COLORS.primary.neutral_300}
                        style={{
                          minHeight: 140,
                          maxHeight: 222,
                          minWidth: 140,
                          maxWidth: 222,
                        }}
                      />
                      <PrimaryText
                        fontSize={24}
                        fontWeight={800}
                        style={styles.neutral}
                      >
                        {`Top Employees\nOn The Way`}
                      </PrimaryText>
                    </View>
                  ) : (
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      {topDonors &&
                        topDonors.map((item, index) => {
                          return (
                            <BusinessTopDonorCard
                              key={item.id}
                              item={item}
                              index={index}
                            />
                          );
                        })}
                    </View>
                  )}
                </View>
              </ShadowContainer>
              {/* )} */}

              <View style={{ flexGrow: 1 }}>
                {/** ROW 2 */}
                <View style={styles.row}>
                  {/** TOP EMPLOYEE DONORS */}
                  <ShadowContainer style={[styles.card, { flex: 2 }]}>
                    <View
                      style={{
                        flex: 2,
                        justifyContent: "space-between",
                      }}
                    >
                      <PrimaryText
                        fontSize={20}
                        fontWeight={400}
                        style={[styles.cardTitle, { marginBottom: 12 }]}
                      >
                        {`Current Campaign`}
                      </PrimaryText>
                      {!!activeCampaign ? (
                        <>
                          <View style={styles.row}>
                            <View style={styles.campaignPhoto}>
                              <View style={styles.cover_img_v}>
                                <Image
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: 12,
                                  }}
                                  source={{ uri: selectedOrg?.coverPhoto }}
                                  resizeMode={"contain"}
                                />
                              </View>
                            </View>
                            <View style={{ flex: 1, marginLeft: 24 }}>
                              <PrimaryText
                                fontSize={17}
                                fontWeight={900}
                                style={{ alignText: "left" }}
                              >
                                {activeCampaign?.name}
                              </PrimaryText>
                              <PrimaryText fontSize={14} fontWeight={400}>
                                {activeCampaign?.about}
                              </PrimaryText>
                            </View>
                          </View>
                          <View style={{ marginTop: 12 }}>
                            <PrimaryText
                              fontSize={24}
                              fontWeight={400}
                              style={[styles.cardTitle, { marginBottom: 0 }]}
                            >
                              {`Goal:`}
                              <PrimaryText
                                fontSize={24}
                                fontWeight={800}
                                style={[styles.titleBlue, { marginLeft: 8 }]}
                              >
                                {dollarFormatter
                                  .format(activeCampaign?.goal)
                                  .slice(0, -3)}
                              </PrimaryText>
                            </PrimaryText>
                          </View>
                          <LinearProgressWithLabel
                            value={
                              (activeCampaign?.raised /
                                activeCampaign?.goal) *
                              100
                            }
                            fontSize={14}
                            fontWeight={400}
                            rightText={"Remaining:"}
                            rightValue={dollarFormatter
                              .format(activeCampaign?.goal)
                              .slice(0, -3)}
                            rightTextStyle={{
                              color: COLORS.primary.darkBlue,
                            }}
                            rightValueStyle={{
                              color: COLORS.primary.lightBlue,
                              fontWeight: 700,
                            }}
                            height={15}
                          />
                          <View
                            style={[
                              styles.row,
                              { marginTop: 18, marginBottom: 8 },
                            ]}
                          >
                            <PrimaryText fontSize={17} fontWeight={400}>
                              {`Date Range: `}
                              <PrimaryText
                                fontSize={17}
                                fontWeight={800}
                                style={[styles.titleBlue, { marginLeft: 8 }]}
                              >
                                {moment(activeCampaign?.startDate).format(
                                  "MM/DD"
                                ) +
                                  " - " +
                                  moment(activeCampaign?.endDate).format(
                                    "MM/DD"
                                  )}
                              </PrimaryText>
                            </PrimaryText>
                            <PrimaryText fontSize={17} fontWeight={400}>
                              {`Match Percentage: `}
                              <PrimaryText
                                fontSize={17}
                                fontWeight={800}
                                style={[styles.titleBlue, { marginLeft: 8 }]}
                              >
                                100%
                              </PrimaryText>
                            </PrimaryText>
                          </View>
                          <View style={styles.row}>
                            <PrimaryText fontSize={17} fontWeight={400}>
                              {`Backers: `}
                              <PrimaryText
                                fontSize={17}
                                fontWeight={800}
                                style={[styles.titleBlue, { marginLeft: 8 }]}
                              >
                                {activeCampaign?.backers}
                              </PrimaryText>
                            </PrimaryText>
                          </View>
                        </>
                      ) : (
                        <View style={styles.center}>
                          <FiIcons.FiCalendar
                            color={COLORS.primary.neutral_300}
                            style={{
                              minHeight: 140,
                              maxHeight: 222,
                              minWidth: 140,
                              maxWidth: 222,
                            }}
                          />
                          <PrimaryText
                            fontSize={24}
                            fontWeight={800}
                            style={styles.neutral}
                          >
                            {`No Active Campaigns... Yet`}
                          </PrimaryText>
                        </View>
                      )}
                    </View>
                  </ShadowContainer>

                  {/** TOP CAUSES */}
                  <ShadowContainer style={styles.card}>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <PrimaryText
                        fontSize={20}
                        fontWeight={400}
                        style={styles.cardTitle}
                      >
                        {`Top Causes`}
                      </PrimaryText>
                      {!topCauses?.length > 0 ? (
                        <View
                          style={[
                            styles.center,
                            { transform: [{ rotate: "90deg" }] },
                          ]}
                        >
                          <FiIcons.FiBarChart2
                            color={COLORS.primary.neutral_300}
                            style={{
                              minHeight: 100,
                              maxHeight: 160,
                              minWidth: 100,
                              maxWidth: 160,
                            }}
                          />
                        </View>
                      ) : (
                        <View style={{ flex: 1 }}>
                          {topCauses &&
                            topCauses.map((item, index) => {
                              let name = "Old Cause";
                              const cause = causes?.find(
                                (x) =>
                                  x.value === item.causeId ||
                                  x.rayze === item.causeId
                              );
                              if (cause) {
                                name = cause.name;
                              }
                              return (
                                <BusinessTopCausesCard
                                  key={item.causeId}
                                  item={item}
                                  index={index}
                                  name={name}
                                />
                              );
                            })}
                        </View>
                      )}
                    </View>
                  </ShadowContainer>
                </View>


              </View>
            </View>
            {/** Top Nonprofits */}
            <ShadowContainer style={styles.card}>
              <PrimaryText
                fontSize={20}
                fontWeight={400}
                style={styles.cardTitle}
              >
                {`Top Nonprofits Supported`}
              </PrimaryText>
              {!topNonprofits?.length > 0 ? (
                <View style={styles.center}>
                  <FiIcons.FiGlobe
                    color={COLORS.primary.neutral_300}
                    style={{
                      minHeight: 140,
                      maxHeight: 222,
                      minWidth: 140,
                      maxWidth: 222,
                    }}
                  />
                  <PrimaryText
                    fontSize={24}
                    fontWeight={800}
                    style={styles.neutral}
                  >
                    {`Awaiting Greatness`}
                  </PrimaryText>
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 20,
                    justifyContent: getResponsiveValue({
                      xs: "center",
                      md: "flex-start",
                    }),
                  }}
                >
                  {topNonprofits &&
                    topNonprofits.map((item, index) => {
                      return (
                        <BusinessTopNonprofitCard
                          key={item?.organization?.id}
                          item={item}
                          index={index}
                        />
                      );
                    })}
                </View>
              )}
            </ShadowContainer>
          </View>
          {/* )} */}
        </View>
      )}
    </>
  );
}

const getStyles = (getResponsiveValue) =>
  StyleSheet.create({
    activity: {
      flex: 1,
      width: "100%",
      backgroundColor: COLORS.primary.white,
    },
    container: {
      flex: 1,
      width: "100%",
      paddingTop: 8,
      backgroundColor: COLORS.primary.white,
    },
    body: {
      flex: 1,
      width: "100%",
      alignItems: "center",
    },
    header: {
      color: COLORS.primary.darkBlue,
    },
    row: {
      width: "100%",
      flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    card: {
      margin: 24,
    },
    cardTitle: {
      color: COLORS.primary.darkBlue,
      marginBottom: 12,
    },
    titleBlue: {
      color: COLORS.primary.lightBlue,
    },
    statGreen: {
      color: COLORS.primary.green,
      textAlign: "center",
    },
    statBlue: {
      color: COLORS.primary.lightBlue,
      textAlign: "center",
    },
    neutral: {
      color: COLORS.primary.neutral_300,
      textAlign: "center",
      marginTop: 12,
    },
    center: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      // backgroundColor:'red'
      // marginBottom: 24
    },
    cover_img_v: {
      width: "100%",
      aspectRatio: 16 / 9,
    },
    campaignPhoto: {
      width: "40%",
    },
    progressBar: {
      width: "100%",
      height: 12,
      backgroundColor: COLORS.primary.neutral,
      borderRadius: 6,
      // marginTop: 12,
      overflow: "hidden",
    },
    progress: {
      width: "100%",
      height: "100%",
      backgroundColor: COLORS.primary.lightBlue,
      borderRadius: 6,
    },
  });
