import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { DONATION_STATUSES } from "constants/Data";
import { COLORS } from "constants/Colors";
import * as FiIcons from "react-icons/fi";
import { StyleSheet, TouchableOpacity } from "react-native-web";
import moment from "moment";

export const BusinessDonationCard = ({ item, onPress }) => {
  const {
    userId,
    amount,
    date,
    recurring,
    organization = {},
    match = 0,
    corporateStatus = 0,
    anonymous = false,
    companyId
  } = item;



  

  const [user, setUser] = useState(item?.user || {});

  useEffect(() => {
    if (anonymous) {
      setUser({
        firstName: "Anonymous",
        lastName: "",
        profilePic: "",
      });
      return;
    }
   if (userId) getUser(userId, setUser);
  }, [userId]);

  let color = COLORS.green;
  switch (corporateStatus) {
    case 0:
      color = COLORS.primary.orange;
      break;
    case 1:
      color = COLORS.primary.green;
      break;
    case 2:
      color = COLORS.primary.error;
      break;
    default:
      color = COLORS.orange;
      break;
  }

  if (!date) return null;

  return (
    <TableRow hover onClick={onPress}>
      <TableCell width={36}>
        <ProfilePic uri={user?.profilePic} size={36} />
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText fontSize={18} fontWeight={900}>
          {user?.firstName ? getUsersName(user) : ''}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {organization?.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {moment(date?.toDate() ? date.toDate() : date).format("MM/DD/YYYY")}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {recurring ? "recurring" : "one-time"}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {dollarFormatter.format(amount)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {companyId ? '-' : dollarFormatter.format(match)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {dollarFormatter.format(amount + match)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500} style={{ color }}>
          {companyId ? '-' : DONATION_STATUSES[corporateStatus]?.label}
        </PrimaryText>
      </TableCell>
    </TableRow>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 4,
  },
});
