import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View } from "react-native";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDonations } from "context/DonationsContext";
import { DonationCard } from "components/Cards/DonationCard";
import { getUser } from "services/user-service";
import { useUsers } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { useOrganization } from "context/OrganizationContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { ActivityIndicator, TouchableOpacity } from "react-native-web";
import { functions } from "utils/firebase";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { CustomInput } from "components/Inputs/CustomInput";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { Button } from "components/Common/Button";
import { FiUpload } from "react-icons/fi";
import { dollarFormatter, getUsersName } from "utils/helpers";
import moment from "moment";
import { DONATION_STATUSES } from "constants/Data";
import exportCSV from "utils/export-csv";
import useMediaQuery from "hooks/useMediaQuery";

export const Donations = () => {
  const navigate = useNavigate();
  const { getResponsiveValue } = useMediaQuery();
  const { recentDonations } = useDonations();
  const { setSelectedUser } = useUsers();
  const { selectedOrg } = useOrganization();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    let temp = [...recentDonations];
    if (search) {
      temp = temp.filter(
        (item) =>
          item.organization?.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item.user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.user?.lastName.toLowerCase().includes(search.toLowerCase())
      );
    }
    setFiltered(temp);
  }, [search, recentDonations]);

  function userSelected(donation) {
    let userId = donation?.userId;
    if (!userId) return;
    getUser(userId, onGotUser);
  }

  function onGotUser(user) {
    setSelectedUser(user);
    navigate(Links.DonorDetails);
  }

  const handleSetUpStripe = () => {
    if (!selectedOrg) return;

    setLoading(true);
    let data = { ...selectedOrg };
    data.redirect = "https://org.rayzeapp.com/donations";
    const stringifiedOrg = JSON.stringify(data);

    functions
      .httpsCallable("getAccountLink")(stringifiedOrg)
      .then((response) => {
        // console.log("test", response)
        const { url: uri, statusCode } = response.data;
        if (uri && !statusCode) {
          window.location.href = uri;
        } else {
          console.log(
            "Error getting account setup link: ",
            response?.raw?.message
          );
        }
      })
      .catch((err) => {
        console.log("handleSetUpStripe Error: ", err);
        setLoading(false);
      });
  };

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };

  if (loading) {
    return (
      <View
        style={[styles.container, { paddingTop: "80%", alignItems: "center" }]}
      >
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  // if (!selectedOrg?.stripeComplete) {
  // 	return (
  // 		<View
  // 			style={[styles.container, { paddingTop: "25%", alignItems: "center" }]}
  // 		>
  // 			<TouchableOpacity onPress={handleSetUpStripe}>
  // 				<PrimaryText style={styles.URL}>{`${
  // 					selectedOrg.stripeID ? "Continue" : "Start"
  // 				} Stripe Setup`}</PrimaryText>
  // 			</TouchableOpacity>
  // 		</View>
  // 	);
  // }

  const onExportCSV = async () => {
    const exportData = await Promise.all(
      filtered.map(async (item) => {
        const { userId, amount, date, recurring } = item || {};

        let user = item?.user;

        const setUser = (data) => {
          user = data || { firstName: "Anonymous", lastName: "" };
        };

        if (!user) {
          // console.log('no user')
          userId && (await getUser(userId, setUser));
        }

        // console.log('item', user?.email)

        return {
          NAME: user ? getUsersName(user) : item?.name,
          EMAIL: item?.email || user?.email,
          DATE: moment(date?.toDate?.()).format("MM/DD/YYYY"),
          FREQUENCY: recurring ? "Recurring" : "One-Time",
          "AMOUNT GIVEN": dollarFormatter.format(amount),
        };
      })
    );

    exportCSV({
      data: exportData,
      filename: "Donations",
    });
  };

  return (
    // <View style={styles.container}>
    <ShadowContainer style={styles.container}>
      <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
        Donations
      </PrimaryText>
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          marginVertical: 12,
        }}
      >
        <View
          style={{
            flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
            alignItems: "center",
            justifyContent: getResponsiveValue({
              xs: "center",
              md: "space-between",
            }),
            width: "100%",
            gap: 12,
          }}
        >
          <CustomInput
            placeholder={"Search"}
            style={{ width: "100%", maxWidth: 400 }}
            onChangeText={setSearch}
            value={search}
            backgroundColor={COLORS.primary.white}
          />

          <Button
            title={"Export Report"}
            onPress={onExportCSV}
            iconLeft={
              <FiUpload
                size={18}
                color={COLORS.primary.white}
                style={{ marginRight: 6 }}
              />
            }
          />
        </View>
      </View>

      <TableContainer>
        <Table
          className="table"
          style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ textAlign: "left" }}>Name</TableCell>
              <TableCell>Payment ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {currentTableData() &&
              currentTableData().map((el, i) => {
                return (
                  <DonationCard
                    item={el}
                    key={el?.id || i}
                    onPress={() => userSelected(el)}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <MuiTablePagination
        count={filtered?.length}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(e.target.value);
          setCurrentPage(0);
        }}
      />
    </ShadowContainer>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    // flex: 1,
    height: "100%",
    width: "100%",
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    marginBottom: 12,
  },
  URL: {
    fontSize: 20,
    fontWeight: 800,
    color: COLORS.primary.lightBlue,
  },
});
