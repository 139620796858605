import { Box } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";

export default function SettingsTab({ activeTab, onChange, options }) {
  return (
    <Box
      height={50}
      borderRadius="10px"
      border={1}
      borderColor={COLORS.primary.neutral_200}
      backgroundColor={COLORS.primary.neutral}
      display="flex"
      flexDirection="row"
      alignItems="center"
      width={{ xs: "100%", sm: "fit-content" }}
      overflow="auto"
      mt={32}
      gap={3}
    >
      {options?.map((item, index) => (
        <Box
          key={index}
          px={{ xs: 10, sm: 24 }}
          height={50}
          borderRadius="10px"
          backgroundColor={
            activeTab === item?.label
              ? COLORS.primary.white
              : COLORS.primary.neutral
          }
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: "pointer" }}
          onClick={() => onChange(item?.label)}
          boxShadow={
            activeTab === item?.label
              ? "2px 0 2px 0 rgba(124, 124, 124, 0.19)"
              : 0
          }
        >
          <PrimaryText
            fontSize={17}
            fontWeight={activeTab === item?.label ? 700 : 400}
          >
            {item?.label}
          </PrimaryText>
        </Box>
      ))}
    </Box>
  );
}
