import {
	getOrgPromise,
	updateOrganization
} from "services/organizations-service";
import { firestore, functions, auth } from "utils/firebase";
import firebase from "firebase/compat/app";

export const getEmployeeMatchDonations = (selectedOrg, setHistory, users) => {
	return firestore
		.collection("donations")
		.where("corporateMatch", "==", selectedOrg?.id) // selectedOrg?.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let history = [];
				querySnapshot.forEach((doc) => {
					history.push({
						corporateStatus: 0,
						...doc.data(),
						id: doc.id
					});
				});

				if (history.length < 1) return setHistory([]);

				let promises = history.map((item, index) =>
					getOrgPromise(item, item.orgId)
				);
				Promise.all(promises).then((donations) => {
					donations = donations.map((item, index) => {
						return {
							...item,
							user: users.find((x) => x.id === item.userId)
						};
					});

					setHistory(donations);
				});
			},
			(e) => {
				console.log("getRecentDonations error", e);
			}
		);
};

export const getOneTimeBusinessDonations = (selectedOrg, setHistory, users) => {
	return firestore
		.collection("donations")
		.where("companyId", "==", selectedOrg?.id) // selectedOrg?.id)
		.onSnapshot(
			(querySnapshot) => {
				let history = [];
				querySnapshot.forEach((doc) => {
					history.push({
						corporateStatus: 0,
						...doc.data(),
						id: doc.id
					});
				});

				if (history.length < 1) return setHistory([]);

				let promises = history.map((item, index) =>
					getOrgPromise(item, item.orgId)
				);
				Promise.all(promises).then((donations) => {
					donations = donations.map((item, index) => {
						return {
							...item,
							user: { firstName: selectedOrg.name, lastName: "" },
							corporateStatus: 1
						};
					});

					setHistory(donations);
				});
			},
			(e) => {
				console.log("getRecentDonations error", e);
			}
		);
};

export const getMatchedDonations = (selectedOrg, setHistory, users) => {
	return firestore
		.collection("donations_corporate_match")
		.where("corporateMatch", "==", selectedOrg?.id) // selectedOrg?.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let history = [];
				querySnapshot.forEach((doc) => {
					history.push({
						...doc.data(),
						id: doc.id
					});
				});

				setHistory(history);
			},
			(e) => {
				console.log("getRecentDonations error", e);
			}
		);
};

export const approveBusinessDonation = (donation, business, defaultPaymentMethod, setLoading) => {
	console.log("approveBusinessDonation", donation.id);
	//handle Stripe transaction here
	createPayment(business, donation, defaultPaymentMethod, setLoading);
	//update donation
	// return updateDonation(donation.id, { corporateStatus: 1 });
};

export const denyBusinessDonation = (donationId) => {
	return updateDonation(donationId, { corporateStatus: 2 });
};

function updateDonation(donationId, data) {
	return firestore.collection("donations").doc(donationId).update(data);
}

function roundToTwo(num) {
	return +(Math.round(num + "e+2") + "e-2");
}

function feePercentage(amount) {
	const feePercentage = 0.01 + 0;
	let application_fee = amount * feePercentage;
	if (application_fee < 0.25) application_fee = 0.25;
	const stripeFee = amount * 0.029 + 0.3;
	const finalFee = application_fee + stripeFee;

	return finalFee;
}


const createPayment = async (business, donation, selectedMethod, setLoading) => {
	const amount = Math.round(
		(business?.matchPercentage * donation?.amount) / 100
	);


	//stripe is taking fee on top of the fee we are taking
	const initialFee = roundToTwo(feePercentage(amount));
	const fee = roundToTwo(feePercentage(initialFee + amount));
	try {
		let paymentIntentResponse;
		if (selectedMethod?.type === "card") {
			paymentIntentResponse = await functions.httpsCallable(
				"createPaymentIntent"
			)({
				amount,
				calculatedFee: fee,
				orgId: donation?.organization?.id,
				recurring: false,
				stripeID: donation?.organization?.stripeID,
				stripeCustomerId: business?.stripeCustomerId,
				paymentMethodId: selectedMethod?.id,
				companyId: business?.id,
			});
		} else {
			paymentIntentResponse = await functions.httpsCallable(
				"createBankPaymentIntent"
			)({
				amount,
				calculatedFee: fee,
				orgId: donation?.organization?.id,
				recurring: false,
				stripeID: donation?.organization?.stripeID,
				stripeCustomerId: business?.stripeCustomerId,
				selectedMethodId: selectedMethod?.id,
				companyId: business?.id,
			});
		}

		const { succeeded, paymentId } = paymentIntentResponse.data;
		if (succeeded) {
			delete donation.organization;
			delete donation.user;
			donation.corporateStatus = 1;
			donation.paymentId = paymentId;

			donation.amount = amount;
			createDonationHistory(donation);
			console.log("Payment successful!!! ", paymentId);
			updateDonation(donation.id, { match: amount, corporateStatus: 1 });
			updateOrganization(
				business,
				{ totalMatchAmount: firebase.firestore.FieldValue.increment(amount) },
				[],
				[],
				() => { }
			);
		}
	} catch (err) {
		console.log("Payment error occurred!!! ", err);
	}
	setLoading(false);

};

export const oneTimeDonation = async (
	business,
	org,
	amount,
	selectedMethod,
	coverFees,
	finalFee,
	onSuccess
) => {
	// console.log('one time', amount)
	try {
		if (selectedMethod) {
			let paymentIntentResponse;
			if (selectedMethod?.type === "card") {
				paymentIntentResponse = await functions.httpsCallable(
					"createPaymentIntent"
				)({
					amount,
					calculatedFee: finalFee,
					orgId: org?.id,
					recurring: false,
					stripeID: org?.stripeID,
					stripeCustomerId: business?.stripeCustomerId,
					paymentMethodId: selectedMethod?.id,
					companyId: business?.id,
				});
			} else {
				paymentIntentResponse = await functions.httpsCallable(
					"createBankPaymentIntent"
				)({
					amount,
					calculatedFee: finalFee,
					orgId: org?.id,
					recurring: false,
					stripeID: org?.stripeID,
					stripeCustomerId: business?.stripeCustomerId,
					selectedMethodId: selectedMethod?.id,
					companyId: business?.id,
				});
			}

			const { succeeded, paymentId } = paymentIntentResponse.data;
			if (succeeded) {
				onSuccess();
				console.log("Payment successful!!! ", paymentId);
				updateOrganization(
					business,
					{
						totalOneTimeDonations: firebase.firestore.FieldValue.increment(amount)
					},
					[],
					[],
					() => { }
				);
			} else {
				window.alert(
					"There was an error processing your donation. Please try again."
				);
				onSuccess();
			}
		} else {
			const transferResponse = await functions
				.httpsCallable('createDonationFromWallet')({
					amount,
					destination: org?.stripeID,
					stripeCustomerId: business?.stripeCustomerId,
					customerId: business.id, // rayze user id
					customerName: business.name, // rayze user name
					orgId: org?.id, // nonprofit id
					orgName: org?.name, // nonprofit name
					companyId: null, // company id
					ein: org?.ein, // nonprofit ein
					coverFees,
					finalFee,
					companyId: business?.id,
				})
				.catch(error => {
					console.log('error', error);
				});

			console.log('transferResponse', transferResponse);
			if (transferResponse?.data?.error) {
				window.alert('Error', transferResponse?.data?.error);
				onSuccess();
			} else {
				updateOrganization(
					business,
					{
						totalOneTimeDonations: firebase.firestore.FieldValue.increment(amount)
					},
					[],
					[],
					() => { }
				);
				onSuccess();
			}
		}
	} catch (err) {
		console.log("Payment error occurred!!! ", err);
	}
};

export const createDonationHistory = (donation) => {
	console.log("createDonationHistory", donation);
	const newDonation = {
		...donation,
		date: new Date()
	}
	// donation.date = new Date();
	firestore
		.collection("donations_corporate_match")
		.doc(newDonation.id)
		.set(newDonation);
};

export const getDonationHistoryByOrgId = async (
	selectedUser,
	selectedOrg,
	setHistory,
	setLoading
) => {
	firestore
		.collection("donations")
		.where("corporateMatch", "==", selectedOrg.id)
		.where("userId", "==", selectedUser.id)
		.get()
		.then((querySnapshot) => {
			let history = [];
			querySnapshot.forEach((doc) => {
				const item = {
					...doc.data(),
					id: doc.id
				}
				item.date = item.date.toDate();
				history.push(item);
			});

			let promises = history.map((item, index) =>
				getOrgPromise(item, item.orgId)
			);
			Promise.all(promises).then((donations) => {
				

				setHistory(donations);
				setLoading(false);
			});


		})
		.catch((e) => {
			setLoading(false);
			console.log("getDonationHistoryByOrgId error", e);
		});
};