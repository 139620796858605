import React, { useState, createContext, useEffect, useContext, useMemo } from "react";
import { getEmployeeMatchDonations, getMatchedDonations, getOneTimeBusinessDonations } from "services/businesses/business-donations-service";
import { useOrganization } from "../OrganizationContext";
import { getBusinessRayzeAccount, getBusinessRayzeAccountTransactions } from "services/businesses/business-wallet-service";
import { functions } from "utils/firebase";

export const BusinessDonationsContext = createContext();

export const BusinessDonationsProvider = ({ children }) => {
  const { selectedOrg, users } = useOrganization();

  const [employeeMatchDonations, setEmployeeMatchDonations] = useState([]);
  const [matchedDonations, setMatchedDonations] = useState([]);
  const [getDonations, setGetDonations] = useState(false);
  const [ourDonations, setOurDonations] = useState([]);
  const [ourRecurringDonations, setOurRecurringDonations] = useState([]);
  const [selectedDonation, setSelectedDonation] = useState(null);
  const [topDonors, setTopDonors] = useState([]);
  const [topNonprofits, setTopNonprofits] = useState([]);
  const [topCauses, setTopCauses] = useState([]);

  //stats
  const [totalDonationsYTD, setTotalDonationsYTD] = useState(0);
  const [totalMatchedDonationsYTD, setTotalMatchedDonationsYTD] = useState(0);
  const [totalHoursVolunteeredYTD, setTotalHoursVolunteeredYTD] = useState(0);
  const [totalNonprofitsDonatedToYTD, setTotalNonprofitsDonatedToYTD] = useState(0);


  //business wallet
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [totalDonationsThisYear, setTotalDonationsThisYear] = useState(0);
  const [totalDonationsThisMonth, setTotalDonationsThisMonth] = useState(0);
  const [rayzeAccount, setRayzeAccount] = useState(null);
  const [rayzeAccountBalance, setRayzeAccountBalance] = useState(0);
  const [rayzeAccountTransactions, setRayzeAccountTransactions] = useState([]);
  const [monthlyDeposits, setMonthlyDeposits] = useState(0);
  const [monthlyWithdrawals, setMonthlyWithdrawals] = useState(0);
  const [thisYearsDepositsByMonth, setThisYearsDepositsByMonth] = useState([]);
  const [thisYearsWithdrawalsByMonth, setThisYearsWithdrawalsByMonth] = useState([]);
  const [monthlySentToEmployees, setMonthlySentToEmployees] = useState(0);

  const allCompanyDonations = useMemo(() => {
    const filteredRayzeAccountTransactions = rayzeAccountTransactions.filter(x => x.type === 'donation' || x.type === 'deposit');

    return filteredRayzeAccountTransactions.concat(ourDonations)
  }, [ourDonations, rayzeAccountTransactions]);

  useEffect(() => {
    if (!selectedOrg) return;
    const sub = getBusinessRayzeAccount(selectedOrg, setRayzeAccount);
    fetchPaymentMethods();
    return () => sub();
  }, [selectedOrg]);

  useEffect(() => {
    if (!rayzeAccount) return;
    setRayzeAccountBalance(rayzeAccount.balance || 0);
    const sub = getBusinessRayzeAccountTransactions(rayzeAccount, setRayzeAccountTransactions);
    return () => sub();
  }, [rayzeAccount]);

  useEffect(() => {
    if (!rayzeAccountTransactions.length) return;
    const rayzeAccountTransactionsThisYear = rayzeAccountTransactions.filter(x => x.date.toDate().getFullYear() === selectedMonth.getFullYear());
    const rayzeAccountTransactionsThisMonth = rayzeAccountTransactionsThisYear.filter(x => x.date.toDate().getMonth() === selectedMonth.getMonth());
    let deposits = 0;
    let withdrawals = 0;
    let sentToEmployees = 0;
    let depositsByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let withdrawalsByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    rayzeAccountTransactionsThisYear.forEach((x) => {
      if (x.type === 'deposit') {
        depositsByMonth[x.date.toDate().getMonth()] = (depositsByMonth[x.date.toDate().getMonth()] || 0) + x.amount;
      } else if (x.type === 'withdrawal') {
        withdrawalsByMonth[x.date.toDate().getMonth()] = (withdrawalsByMonth[x.date.toDate().getMonth()] || 0) + x.amount;
      }
    });
    rayzeAccountTransactionsThisMonth.forEach((x) => {
      if (x.type === 'deposit') {
        deposits += x.amount;
      } else if (x.type === 'withdrawal') {
        withdrawals += x.amount;
      } else if (x.type === 'transfer') {
        sentToEmployees += x.amount;
      }
    });

    //wallet stuff
    const rayzeAccountDonationsThisYear = rayzeAccountTransactionsThisYear.filter(x => x.type === "donation");
    let totalDonationsThisYear = rayzeAccountDonationsThisYear.reduce(
      (a, b) => a + (b.amount),
      0
    );
    //calculate total donations for this month
    const currentMonth = selectedMonth.getMonth();
    const donationsThisMonth = rayzeAccountDonationsThisYear.filter(
      (x) => x.date.toDate().getMonth() === currentMonth
    );
    let totalDonationsThisMonth = donationsThisMonth.reduce(
      (a, b) => a + (b.amount),
      0
    );

    totalDonationsThisMonth = Math.round(totalDonationsThisMonth * 100) / 100;

    // setTotalDonationsThisYear(totalDonationsThisYear);
    setTotalDonationsThisMonth(totalDonationsThisMonth);

    setMonthlyDeposits(deposits);
    setMonthlyWithdrawals(withdrawals);
    setThisYearsDepositsByMonth(depositsByMonth);
    setThisYearsWithdrawalsByMonth(withdrawalsByMonth);
    setMonthlySentToEmployees(sentToEmployees);
  }, [rayzeAccountTransactions, selectedMonth]);

  useEffect(() => {
    if (!allCompanyDonations.length) return;
    const allCompanyDonationsThisYear = allCompanyDonations.filter(x => x.date.toDate().getFullYear() === selectedMonth.getFullYear());
    const allCompanyDonationsThisMonth = allCompanyDonationsThisYear.filter(x => x.date.toDate().getMonth() === selectedMonth.getMonth());
    let totalDonations = allCompanyDonationsThisYear.reduce(
      // (a, b) => a + (b.amount - b.fee),
      (a, b) => a + b.amount,
      0
    );
    //round to 2 digits
    totalDonations = Math.round(totalDonations * 100) / 100;

    setTotalDonationsThisYear(totalDonations);

  }, [allCompanyDonations, selectedMonth]);

  useEffect(() => {
    if (!selectedOrg || !users) return;
    const sub = getEmployeeMatchDonations(
      selectedOrg,
      setEmployeeMatchDonations,
      users
    );
    const matchedSub = getMatchedDonations(
      selectedOrg,
      setMatchedDonations,
      users
    );
    const oneTimeSub = getOneTimeBusinessDonations(
      selectedOrg,
      setOurDonations,
      users
    )

    return () => {
      sub();
      matchedSub();
      oneTimeSub();
    };
  }, [selectedOrg, users]);

  useEffect(() => {
    //get top employee donors by sum of donations
    if (employeeMatchDonations?.length < 1) {
      setTotalNonprofitsDonatedToYTD(0);
      setTotalDonationsYTD(0);
      setTopDonors([]);
      setTopNonprofits([]);
      setTopCauses([]);
      return;
    }
    const topDonors = Object.values(
      employeeMatchDonations.reduce((a, e) => {
        const userId = e?.userId;
        a[userId] ??= { ...e, userId, amount: 0 };
        a[userId].amount += e?.amount;
        return a;
      }, {})
    );
    topDonors.sort((a, b) => (a.amount > b.amount ? -1 : 1));

    const topNonprofits = Object.values(
      employeeMatchDonations.reduce((a, e) => {
        const orgId = e?.orgId;
        a[orgId] ??= { ...e, orgId, amount: 0 };
        a[orgId].amount += e?.amount;

        a[orgId].backers === undefined
          ? a[orgId].backers = new Set([e?.userId])
          : a[orgId].backers.add(e?.userId);
        return a;
      }, {})
    );
    topNonprofits.sort((a, b) => (a.amount > b.amount ? -1 : 1));

    console.log('topNonprofits', topNonprofits)

    let allCauses = [];
    employeeMatchDonations.forEach((x) => {
      const causes = x?.organization?.causes;
      causes &&
        causes.forEach((cause) => {
          allCauses.push({ causeId: cause, total: 1 });
        });
    });
    //count causes
    const topCauses = Object.values(
      allCauses.reduce((a, e) => {
        const causeId = e.causeId;
        a[causeId] ??= { ...e, causeId, total: 0 };
        a[causeId].total += e.total;
        return a;
      }, {})
    );
    topCauses.sort((a, b) => (a.total > b.total ? -1 : 1));
    topCauses.forEach((x) => {
      x.percent = (x.total / allCauses.length) * 100;
    });

    setTotalNonprofitsDonatedToYTD(topNonprofits.length);
    let allDonations = employeeMatchDonations.concat(ourDonations);
    const thisYearsDonations = allDonations.filter(x => x.date.toDate().getFullYear() === new Date().getFullYear());
    let totalDonations = thisYearsDonations.reduce(
      (a, b) => a + (b.amount),
      0
    );
    // console.log('thisYearsDonations', thisYearsDonations)
    setTotalDonationsYTD(totalDonations);

    setTopDonors(topDonors.slice(0, 5));
    setTopNonprofits(topNonprofits.slice(0, 5));
    setTopCauses(topCauses.slice(0, 5));





    //round to 2 digits
    totalDonations = Math.round(totalDonations * 100) / 100;



  }, [employeeMatchDonations]);

  useEffect(() => {
    //get matched donations YTD
    if (!matchedDonations) return;
    let matchedDonationsYTD = matchedDonations.filter(
      (x) => x.date.toDate().getFullYear() === new Date().getFullYear()
    );
    setTotalMatchedDonationsYTD(
      matchedDonationsYTD.reduce((a, e) => a + e?.amount, 0)
    );
    // console.log('hjere', matchedDonationsYTD)
  }, [matchedDonations]);

  function fetchPaymentMethods() {
    if (!selectedOrg?.stripeCustomerId) setPaymentMethods([]);
    functions
      .httpsCallable("getPaymentMethods")({
        stripeCustomerId: selectedOrg?.stripeCustomerId,
      })
      .then((res) => {
        const customer = res?.data?.customer;
        const paymentMethods = res?.data?.paymentMethods?.data;
        if (!paymentMethods) setPaymentMethods([]);
        const findIndex = paymentMethods.findIndex(
          (x) => x.id === customer?.invoice_settings?.default_payment_method
        );
        if (findIndex > -1) {
          paymentMethods[findIndex].isDefault = true;
        }
        const uniqueMethods = paymentMethods
          .filter(
            (thing, index, self) =>
              self.findIndex((t) => t.card?.last4 === thing.card?.last4) ===
              index
          )
          .sort((a, b) => (a.isDefault > b.isDefault));

        console.log('uniqueMethods', uniqueMethods)
        setPaymentMethods(uniqueMethods);
      })
      .catch((err) => {
        console.log("getPaymentMethods err", err);
      });
  }

  return (
    <BusinessDonationsContext.Provider
      value={{
        employeeMatchDonations,
        setGetDonations,
        ourDonations,
        ourRecurringDonations,
        selectedDonation,
        setSelectedDonation,
        topDonors,
        topNonprofits,
        topCauses,
        totalDonationsYTD,
        totalMatchedDonationsYTD,
        totalHoursVolunteeredYTD,
        totalNonprofitsDonatedToYTD,
        rayzeAccount,
        paymentMethods,
        rayzeAccountBalance,
        setRayzeAccountBalance,
        rayzeAccountTransactions,
        monthlyDeposits,
        setMonthlyDeposits,
        monthlyWithdrawals,
        setMonthlyWithdrawals,
        totalDonationsThisYear,
        totalDonationsThisMonth,
        thisYearsDepositsByMonth,
        setThisYearsDepositsByMonth,
        thisYearsWithdrawalsByMonth,
        setThisYearsWithdrawalsByMonth,
        monthlySentToEmployees,
        selectedMonth, setSelectedMonth
      }}
    >
      {children}
    </BusinessDonationsContext.Provider>
  );
};

export const useBusinessDonations = () => useContext(BusinessDonationsContext);
