import React, { useState, createContext, useEffect, useContext } from "react";
import { listenForBusinessCampaigns } from "services/businesses/business-campaign-service";
import { useOrganization } from "../OrganizationContext";
import { useBusinessDonations } from "./BusinessDonationsContext";

export const CampaignsContext = createContext();

export const BusinessCampaignsProvider = ({ children }) => {
  const { selectedOrg } = useOrganization();
  const { employeeMatchDonations } = useBusinessDonations();

  const [ourCampaigns, setOurCampaigns] = useState([]);
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedCampaignDonations, setSelectedCampaignDonations] = useState(
    []
  );

  useEffect(() => {
    if (!selectedOrg) return;

    const sub = listenForBusinessCampaigns(
      selectedOrg,
      setOurCampaigns,
      setActiveCampaigns
    );

    return () => {
      sub();
    };
  }, [selectedOrg]);

  useEffect(() => {
    // console.log(ourCampaigns)
    //get active campaigns
    const active = ourCampaigns.filter(
      (c) =>
        c.startDate <= new Date() && c.endDate >= new Date()
    );
    setActiveCampaigns(active);

    const campaigns = Object.values(
      employeeMatchDonations.reduce((a, e) => {
        const campaignId = e?.campaignId;
        a[campaignId] ??= { ...e, campaignId, backers: 0, donors: [] };
        // a[campaignId].donors ??= [];
        if (!a[campaignId].donors.includes(e?.userId)) {
          a[campaignId].donors.push(e?.userId);
          a[campaignId].backers++;
        }
        return a;
      }, {})
    );
    campaigns.forEach((x) => {
      const findCampaign = ourCampaigns.find((c) => c.id === x?.campaignId);
      if (!findCampaign) return;
      findCampaign.backers = x.backers;
    });

  }, [ourCampaigns]);

  useEffect(() => {
    //get top employee donors by sum of donations
    if (employeeMatchDonations?.length === 0 || activeCampaigns?.length === 0) {
      setActiveCampaign(null);
      return;
    };



    setActiveCampaign(activeCampaigns?.length > 0 ? activeCampaigns[0] : null);
  }, [employeeMatchDonations, activeCampaigns]);

  useEffect(() => {
    if (!selectedCampaign) return;
    const donations = employeeMatchDonations.filter(
      (d) => d?.campaignId === selectedCampaign.id
    );
    setSelectedCampaignDonations(donations);
  }, [selectedCampaign, employeeMatchDonations]);

  return (
    <CampaignsContext.Provider
      value={{
        ourCampaigns,
        activeCampaigns,
        activeCampaign,
        selectedCampaign,
        setSelectedCampaign,
        selectedCampaignDonations,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useBusinessCampaigns = () => useContext(CampaignsContext);
